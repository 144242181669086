import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/juridico',
    name: 'juridico',
    component: () => import('../views/Juridico.vue'),
  },
  {
    path: '/marketing',
    name: 'marketing',
    component: () => import('../views/Marketing.vue'),
  },
  {
    path: '/marketing-categorias',
    name: 'marketingCategorias',
    component: () => import('../views/MarketingCategorias.vue'),
  },
  {
    path: '/marketing-produtos',
    name: 'marketingProdutos',
    component: () => import('../views/MarketingProdutos.vue'),
  },
  {
    path: '/formacao',
    name: 'formacao',
    component: () => import('../views/Formacao.vue'),
  },
  {
    path: '/recrutamento',
    name: 'recrutamento',
    component: () => import('../views/Recrutamento.vue'),
  },
  {
    path: '/utilizadores',
    name: 'utilizadores',
    component: () => import('../views/Utilizadores.vue'),
  },
  {
    path: '/lojas',
    name: 'lojas',
    component: () => import('../views/Lojas.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'about' */ '../views/Login.vue'),
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
});


export default router
