import { createStore } from 'vuex'
import { pt } from 'date-fns/locale'

export default createStore({
  state: {
    load: 0,
    block: 0,
    login: 0,

    user: '',
    filter: null,
    filterConfig: null,

    noResultsText: 'Nenhum resultado encontrado',
    loadingText: 'Carregando...',
    clearAllText: 'Limpar tudo',
    clearValueText: 'Limpar valor',
    noOptionsText: 'Sem opções disponíveis',

    erro: '',
    sucesso: '',

    lang: pt,

  },
  getters: {
  },
  mutations: {
    SETLOAD (state, a) {
      state.load = a
    },
    SETBLOCK (state, a) {
      state.block = a
    },
    SETUSER (state, a) {
      state.user = a
      // a.juridico = 0
      // a.admin = 1
    },
    SETFILTER (state, a) {
      state.filter = a
    },
    SETFILTERCONFIG (state, a) {
      state.filterConfig = a
    },
    SETLogin (state, a) {
      state.login = a
    },
    SETErro (state, a) {
      state.erro = a
    },
    SETSucesso (state, a) {
      state.sucesso = a
    }
  },
  actions: {
  },
  modules: {
  }
})
